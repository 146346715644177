<template>
  <div id="listDetail">
    <div class="list-status-box">
      <img class="status-pic" v-if="order_status == 3" src="@images/finish-status.png" alt/>
      <img class="status-pic" v-if="order_status == 7" src="@images/cancel-status.png" alt/>
      <p class="status" v-if="order_status == 1">待配送</p>
      <p class="status" v-if="order_status == 2">配送中</p>
      <p class="status active" v-if="order_status == 3">已完成</p>
      <p class="status cancel" v-if="order_status == 7">已取消</p>
      <p class="tips" v-if="order_status == 1 && pay_type !== '2'">请尽快备货，配送时点击开始配送修改订单状态</p>
      <p class="tips" v-if="order_status == 2 && pay_type !== '2'">请在完成配送后点击按钮修改订单状态</p>
      <p class="tips" v-if="order_status == 3 && pay_type !== '2'">订单已完成配送，祝老板多多开单</p>
      <p class="tips" v-if="order_status == 7 && pay_type !== '2'">用户主动取消订单</p>
      <p class="tips" v-if="pay_type === '2'">门店交易订单</p>

      <div class="line"></div>
      <div class="status-bottom">
        <div class="status-left" v-if="pay_type !== '2'">
          <p v-if="order_status == 1 || order_status == 2">买家已等待</p>
          <p v-if="order_status == 1 || order_status == 2">{{timestampToTime(create_at)}}</p>
          <p v-if="order_status == 3">总共耗时</p>
          <p v-if="order_status == 3">{{complete_time}}</p>
          <p v-if="order_status == 7">取消原因</p>
          <p v-if="order_status == 7">不想要了/拍错了</p>
        </div>
        <div class="status-right">
          <p v-if="order_status == 1" @click="printTicketBtn">打印小票</p>
          <p class="active" v-if="order_status == 1" @click="handleOrderBtn(id,2)">开始配送</p>
          <p class="active" v-if="order_status == 2" @click="handleOrderBtn(id,3)">完成配送</p>
        </div>
      </div>
    </div>

    <div class="list-address-box" v-if='pay_type !== "2"'>
      <p class="status">收货地址</p>
      <div class="line"></div>
      <p class="tips">{{address}}</p>
      <div class="address-bottom">
        <p class="name">{{contacts}}</p>
        <img src="@images/phone.png" alt/>
        <a class="phone" :href="'tel://' + telephone">{{telephone}}</a>
      </div>
    </div>

    <div class="list-remark-box" v-if='pay_type !== "2"'>
      <div class="title">
        <div class="logo"></div>
        <p>订单备注</p>
      </div>
      <div class="line"></div>
      <div class="remark-bottom">{{remarks ? remarks : '未填写订单备注'}}</div>
    </div>

    <div class="list-goods-box">
      <div class="goods-top">
        <p>所选商品</p>
        <span>共{{product_num}}件</span>
      </div>

      <div class="goods-box">
        <div class="goods-item" v-for="(item) in orderArr" :key="item.id">
          <div class="item-left">
            <div class="none" v-if="item.is_shortage == 1"></div>
            <img
              class="less"
              src="@images/less.png"
              v-if="item.is_shortage == 1 && order_status != 1"
              alt
            />
            <img v-lazy="item.product_cover" alt/>
            <p class="goods-details">{{item.product_name}}</p>
          </div>
          <div class="good-position">
            <p :class="item.is_shortage == 1 ? 'none' :''">x{{item.product_num}}</p>
            <div
              v-if="order_status == 1"
              @click="handleShortageBtn(item.is_shortage, item.product_id, item)"
              :class="item.is_shortage == 0 ? '' : 'active'"
            >{{item.is_shortage == 0 ? '缺货' : '取消缺货'}}
            </div>
            <i v-if="item.is_shortage == 1 && order_status != 1" class="none">已退款</i>
          </div>
        </div>
      </div>

      <div class="goods-price">
        <span>商品金额</span>
        <p>￥{{product_money | fixdTwo}}</p>
      </div>

      <div class="delivery">
        <div>
          <span>配送费</span>
          <p>实付满{{delivery_money}}元，配送费已减免</p>
        </div>
        <i>￥{{product_money >= delivery_money ? 0 : 3}}</i>
      </div>

      <div class="goods-price" v-if="refund != 0">
        <span>缺货退款</span>
        <p>-￥{{ refund | fixdTwo}}</p>
      </div>

      <div class="line"></div>

      <div class="total-box">
        <p>小计：</p>
        <span>￥{{order_status == 1 ? Math.round((total_amount - refund) * 100) / 100 : Math.round((total_amount) * 100) / 100}}</span>
      </div>
    </div>

    <div class="list-code-box">
      <div>
        <p>订单编号：</p>
        <span>{{order_sn}}</span>
      </div>

      <div>
        <p>下单时间：</p>
        <span>{{order_time}}</span>
      </div>
      <div>
        <p>支付方式：</p>
        <span>{{pay_method}}</span>
      </div>

      <div v-if='pay_type !== "2"'>
        <p>如遇缺货：</p>
        <span>其他商品继续配送（缺货商品退款）</span>
      </div>
    </div>

    <p class="out-tips">如收到的商品出现质量、错发、漏发等问题，可申请售后退款</p>
  </div>
</template>

<script>
  import {handleOrder, handleShortage, ordersDetail, printTicket} from "../api/requist";
  import {Dialog, Toast} from "vant";

  export default {
    components: {},
    props: [],
    filters: {
      fixdTwo: function (val) {
        return Math.round(val * 100) / 100;
      }
    },
    data() {
      return {
        id: "",
        product_num: "",
        product_money: "",
        delivery_money: "",
        total_amount: "",
        contacts: "",
        telephone: "",
        province: "",
        complete_time: "",
        city: "",
        county: "",
        address: "",
        order_status: "",
        pay_method: "",
        pay_type: "", // 付款类型 1-线上付款  2-线下付款
        remarks: "",
        order_sn: "",
        create_at: "",
        refund_money: "",
        order_time: "",
        orderArr: [],
        timer: null
      };
    },
    created() {
      this.getInfo();
    },
    mounted() {
    },
    watch: {},
    computed: {
      refund: function () {
        let money = 0;
        this.orderArr.forEach(item => {
          if (item.is_shortage == 1) {
            money += item.product_price * item.product_num;
          }
        });
        return money;
      }
    },
    methods: {
      async printTicketBtn() {
        let formData = new FormData();
        formData.append("merch_id", localStorage.getItem("merch_id"));
        formData.append("orders_id", this.id);
        let res = await printTicket(formData);

        if (res.code === 200) {
          Toast("打印成功");
        }
      },

      async handleShortageBtn(Shortage, pid, item) {
        if (this.order_status != 1) {
          return false;
        }
        let formData = new FormData();
        formData.append("merch_id", localStorage.getItem("merch_id"));
        formData.append("orders_id", this.id);
        formData.append("product_id", pid);
        let res = await handleShortage(formData);
        item.isShowShort = true;
        this.orderArr.forEach(item => {
          if (item.product_id == pid) {
            return item.is_shortage == 1
              ? (item.is_shortage = 0)
              : (item.is_shortage = 1);
          }
        });
        Toast(res.message);
      },
      async getInfo() {
        let formData = new FormData();
        formData.append("merch_id", localStorage.getItem("merch_id"));
        formData.append("orders_id", +this.$route.query.id);
        let res = await ordersDetail(formData);
        if (res.code === 200) {
          this.product_num = res.data.product_num;
          this.product_money = res.data.product_money;
          this.refund_money = res.data.refund_money;
          this.delivery_money = res.data.delivery_money;
          this.total_amount = res.data.total_amount;
          this.contacts = res.data.contacts;
          this.telephone = res.data.telephone;
          this.province = res.data.province;
          this.city = res.data.city;
          this.county = res.data.county;
          this.address = res.data.address;
          this.order_status = res.data.order_status;
          this.pay_method = res.data.pay_method;
          this.remarks = res.data.remarks;
          this.order_sn = res.data.order_sn;
          this.create_at = res.data.create_at / 1000;
          this.refund_money = res.data.refund_money;
          this.complete_time = res.data.complete_time;
          this.order_time = res.data.order_time;
          this.orderArr = res.data.product_order_info;
          this.id = res.data.id;
          this.pay_type = res.data.pay_type;

          this.timer = setInterval(() => {
            this.create_at = +this.create_at + 1000;
          }, 1000);
        }
      },

      timestampToTime(time) {
        let m = parseInt(time / (1000 * 60));
        let s = parseInt((time % (1000 * 60)) / 1000);
        return `${m}分${s}秒`;
      },

      async handleOrderBtn(id, type) {
        let _title = type - 1 === 1 ? '确认开始配送吗' : '确认完成配送吗';
        let _message = type - 1 === 1 ? '点击确认将通知用户订单开始配送' : '点击确认将通知用户订单配送完成';
        Dialog.confirm({
          title: _title,
          message: _message
        }).then(() => {
          Toast.loading({
            message: "请求中...",
            forbidClick: true,
          });
          let formData = new FormData();
          formData.append("merch_id", localStorage.getItem("merch_id"));
          formData.append("orders_id", id);
          formData.append("order_status", type);
          handleOrder(formData).then((res) => {
            Toast.clear();
            // 开始配送
            if (res.code === 200) {
              this.getInfo();
              this.order_status = type;
              if (type === 2) {
                Toast("开始配送");
              } else if (type === 3) {
                Toast("完成配送");
              }
            }
          });
        });
      }
    },
    beforeDestroy() {
      if (this.timer) {
        //如果定时器还在运行 或者直接关闭，不用判断
        clearInterval(this.timer); //关闭
      }
    }
  };
</script>
<style lang="scss">
  @import '../common/css/theme.scss';

  #listDetail {
    min-height: 100vh;
    padding-top: 3.2* 3.75px;
    box-sizing: border-box;
    background: rgba(245, 247, 250, 1);

    .list-status-box {
      position: relative;
      background: rgba(255, 255, 255, 1);
      border-radius: 1* 3.75px;
      margin: 0 16px 12px 16px;
      padding: 20px 16px;
      box-sizing: border-box;

      .status-pic {
        height: 13.33* 3.75px;
        width: 13.33* 3.75px;
        position: absolute;
        top: 3.73* 3.75px;
        right: 3.73* 3.75px;
      }

      .status {
        font-size: 4.27* 3.75px;
        color: rgba(48, 49, 51, 1);
        text-align: left;

        &.active {
          color: $theme-color;
        }

        &.cancel {
          color: #303133;
        }
      }

      .tips {
        text-align: left;
        margin-top: 1.07* 3.75px;
        font-size: 3.2* 3.75px;
        color: rgba(144, 147, 153, 1);
      }

      .line {
        width: 100%;
        height: 0;
        margin: 2.13* 3.75px auto;
        border-bottom: 1px solid rgba(240, 242, 245, 1);
      }

      .status-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .status-left {
          display: flex;
          align-items: center;

          p {
            color: rgba(192, 196, 204, 1);
            font-size: 3.2* 3.75px;

            &:first-child {
              margin-right: 2.13* 3.75px;
            }
          }

          .van-count-down {
            color: rgba(192, 196, 204, 1);
            font-size: 3.2* 3.75px;
          }
        }

        .status-right {
          flex: 1;
          display: flex;
          justify-content: flex-end;

          p {
            padding: 4px 8px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(96, 98, 102, 1);
            border-radius: 1* 3.75px;
            box-sizing: border-box;
            text-align: center;
            font-size: 12px;
            line-height: 12*1.4px;
            color: rgba(96, 98, 102, 1);

            &:last-child {
              margin-left: 2.13* 3.75px;
            }

            &.active {
              border: 1px solid $theme-color;
              color: $theme-color;
            }
          }
        }
      }
    }

    .list-address-box {
      background: rgba(255, 255, 255, 1);
      border-radius: 1* 3.75px;
      margin: 12px 16px;
      padding: 20px 16px;
      box-sizing: border-box;

      .status {
        font-size: 4.27* 3.75px;
        color: rgba(48, 49, 51, 1);
        text-align: left;
      }

      .tips {
        text-align: left;
        font-size: 4.27* 3.75px;
        color: rgba(48, 49, 51, 1);
      }

      .line {
        width: 100%;
        height: 0;
        margin: 2.13* 3.75px auto;
        border-bottom: 1px solid rgba(240, 242, 245, 1);
      }

      .address-bottom {
        margin-top: 8px;
        display: flex;
        align-items: center;

        .name {
          font-size: 3.2* 3.75px;
          color: rgba(144, 147, 153, 1);
          margin-right: 2.13* 3.75px;
        }

        .phone {
          font-size: 3.2* 3.75px;
          color: rgba(144, 147, 153, 1);
          margin-right: 1.07* 3.75px;
        }

        img {
          height: 3.73* 3.75px;
          width: 3.73* 3.75px;
        }
      }
    }

    .list-remark-box {
      background: rgba(255, 255, 255, 1);
      border-radius: 1* 3.75px;
      padding: 20px 16px;
      box-sizing: border-box;
      margin: 12px 16px;

      .title {
        display: flex;
        align-items: center;

        .logo {
          width: 1.3* 3.75px;
          height: 4.13* 3.75px;
          background-color: $theme-color;
          box-shadow: 0* 3.75px 1* 3.75px 1* 3.75px rgba(116, 205, 89, 0.3);
          border-radius: 4px;
          margin-right: 2.13* 3.75px;
        }

        p {
          font-size: 4.27* 3.75px;
          color: rgba(48, 49, 51, 1);
        }
      }

      .line {
        width: 100%;
        height: 0;
        margin: 2.13* 3.75px auto;
        border-bottom: 1px solid rgba(240, 242, 245, 1);
      }

      .remark-bottom {
        text-align: left;
        width: 82.67* 3.75px;
        height: 17.6* 3.75px;
        font-size: 4.27* 3.75px;
        font-weight: bold;
        line-height: 5.87* 3.75px;
        color: rgba(48, 49, 51, 1);
      }
    }

    .list-goods-box {
      background: rgba(255, 255, 255, 1);
      padding: 4.27* 3.75px 4.27* 3.75px 3.2* 3.75px;
      box-sizing: border-box;
      border-radius: 1* 3.75px;
      margin: 12px 16px;

      .goods-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 3.73* 3.75px;
          font-weight: bold;
          color: rgba(48, 49, 51, 1);
        }

        span {
          color: rgba(96, 98, 102, 1);
          font-size: 3.73* 3.75px;
        }
      }

      .goods-box {
        margin-top: 8.53* 3.75px;
        margin-bottom: 8.53* 3.75px;

        .goods-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8.53* 3.75px;

          &:last-child {
            margin-bottom: 0;
          }

          .item-left {
            display: flex;
            position: relative;

            .none {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
              background-color: rgba(255, 255, 255, 0.4);
            }
          }

          img {
            height: 16.8* 3.75px;
            width: 16.8* 3.75px;
            margin-right: 2.13* 3.75px;
          }

          .less {
            left: 0;
            top: 0;
            margin: 0;
            position: absolute;
          }

          .goods-details {
            height: 15.8* 3.75px;
            width: 37.33* 3.75px;
            text-align: left;
            font-size: 3.73* 3.75px;
            line-height: 5.33* 3.75px;
            color: rgba(48, 49, 51, 1);
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap: break-word;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            // &.none {
            //   color: #acadad;
            // }
          }

          .good-position {
            p {
              margin-bottom: 4.27* 3.75px;
              font-size: 4.27* 3.75px;
              font-weight: bold;
              color: rgba(48, 49, 51, 1);

              &.none {
                color: #acadad;
              }
            }

            i {
              color: #c0c4cc;
              font-size: 3.2* 3.75px;
              font-style: normal;
            }

            div {
              width: 17.07* 3.75px;
              height: 6.67* 3.75px;
              border: 1px solid rgba(144, 147, 153, 1);
              font-size: 3.2* 3.75px;
              line-height: 6.67* 3.75px;
              color: rgba(144, 147, 153, 1);
              border-radius: 1* 3.75px;
              text-align: center;
              box-sizing: border-box;

              &.active {
                background: $theme-color;
                border: 1px solid $theme-color;
                color: rgba(255, 255, 255, 1);
              }
            }
          }
        }
      }

      .goods-price {
        display: flex;
        margin-bottom: 3.2* 3.75px;
        align-items: center;
        justify-content: space-between;

        p {
          color: rgba(96, 98, 102, 1);
          font-size: 3.2* 3.75px;
        }

        span {
          color: rgba(48, 49, 51, 1);
          font-size: 3.2* 3.75px;
        }
      }

      .delivery {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.2* 3.75px;

        div {
          display: flex;
          align-items: center;
        }

        span {
          color: rgba(48, 49, 51, 1);
          font-size: 3.2* 3.75px;
          margin-right: 2.13* 3.75px;
        }

        p {
          color: rgba(144, 147, 153, 1);
          font-size: 3.2* 3.75px;
        }

        i {
          font-style: normal;
          color: #606266;
          font-size: 3.2* 3.75px;
        }
      }

      .line {
        border-bottom: 1px solid rgba(240, 242, 245, 1);
        width: 100%;
        margin: 3.2* 3.75px auto;
      }

      .total-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        p {
          color: rgba(48, 49, 51, 1);
          font-size: 4.27* 3.75px;
          font-weight: bold;
        }

        span {
          font-size: 4.27* 3.75px;
          color: rgba(255, 0, 0, 1);
          font-weight: bold;
        }
      }
    }

    .list-code-box {
      margin: 12px 16px;
      background: rgba(255, 255, 255, 1);
      border-radius: 1* 3.75px;
      padding: 5.33* 3.75px 4.27* 3.75px 5.33* 3.75px;
      box-sizing: border-box;

      div {
        margin-bottom: 3.2* 3.75px;
        display: flex;
        align-items: center;
        font-size: 3.2* 3.75px;
        color: rgba(144, 147, 153, 1);

        &:last-child {
          margin-bottom: 0;
        }

        p {
          margin-right: 2.13* 3.75px;
        }
      }
    }

    .out-tips {
      font-size: 2.67* 3.75px;
      color: rgba(144, 147, 153, 1);
      text-align: center;
      padding-bottom: 6.4* 3.75px;
    }
  }
</style>
